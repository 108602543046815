import React from 'react';
import { BreadcrumbComponnetProps, BreadcrumbProps } from '../interfaces';
import './Breadcrumbs.styles.scss';

const Breadcrumbs = (props: BreadcrumbComponnetProps) => {
    const { paths } = props;
    return (
        <div id="finance-breadcrumbs">
            <div className="breadcrumbs">
                <ul className="m-ap-flex">
                    {
                        paths.length > 0 && paths.map((path: BreadcrumbProps, index: number) => (
                            <>
                                {
                                    index < paths.length - 1 && (
                                        <>
                                            <li className="b-link"><a href={path.redirectTo}>{path.title}</a></li>
                                            <li className="b-slace">/</li>
                                        </>
                                    )
                                }
                                {
                                    index === paths.length - 1 && (
                                        <li className="b-nolink">{path.title}</li>
                                    )
                                }
                            </>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default Breadcrumbs;
