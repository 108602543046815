import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import ReceiptHeader from '../../Receipt/ReceiptHeader';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs.componet';
import { journalProps, journalTransProps } from '../interfaces';
import { BreadcrumbProps } from '../../Components/interfaces';
import { AllJournalBreadcrumbItems, sampleJournal } from '../ManualJournalController';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ViewJournalReceipt.styles.scss';


const JournalReceipt = () => {

    const [selectedJournal, setSelectedJournal] = useState<journalProps>(sampleJournal);
    const [basicSectionActive, setBasicSectionActive] = useState<boolean>(true);
    const [transSectionActive, setTransSectionActive] = useState<boolean>(false);
    const [mjFormTransList, setMjFormTransList] = useState<journalTransProps[]>([]);
    const [viewJournalBreadCrumb, setViewJournalBreadCrumb] = useState<BreadcrumbProps[]>(AllJournalBreadcrumbItems);

    useEffect(() => {
        // @ts-ignore
        const journalData = window.mjDetails;
        if (journalData) {
            setSelectedJournal(journalData);
            setMjFormTransList(JSON.parse(journalData.Data));
            const breadcrumbsList = [...viewJournalBreadCrumb];
            breadcrumbsList.push({ title: `Journal Details - ${journalData.JournalID} `, redirectTo: "" });
            setViewJournalBreadCrumb(breadcrumbsList);
        }
    }, [])

    const goToAllJournals = () => {
        navigate('/AllJournals');
    }

    const goToEditJournal = () => {
        //@ts-ignore
        window.redirectFrom = 'ViewJournal'
        navigate('/CreateManualJournal');
    }

    return (
        <div id="view-manual-voucher-container">
            <ReceiptHeader />
            <div className="m-ap-wrap">
                <div className="m-ap-top m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15 pad-l-r-30">
                    <Breadcrumbs paths={viewJournalBreadCrumb} />
                </div>

                <div className="m-ap-title">View Journal Details</div>

                <div className={`m-ap-accordion m-ap-m-b-15 ${basicSectionActive ? 'm-ap-active' : ''}`}>
                    <div className="m-ap-ac-head" onClick={() => setBasicSectionActive(!basicSectionActive)}>
                        <div className="m-ap-sub-title">Basic Details</div>
                        <div className="m-ap-arrow"></div>
                    </div>
                    {
                        basicSectionActive && (
                            <div className="m-ap-ac-content">
                                <div className="m-ap-form">
                                    <div className="m-ap-row">
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Journal ID</label>
                                            <input type="text" id="journal_id" name="journal_id"
                                                value={selectedJournal.JournalID} readOnly />
                                        </div>
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Transaction Date</label>
                                            <input type="text" id="journal_transdate" name="journal_transdate"
                                                value={selectedJournal.UploadDate} readOnly />
                                        </div>

                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Post Date</label>
                                            <input type="text" id="post_date" name="post_date"
                                                value={selectedJournal.PostingDate} readOnly />
                                        </div>
                                        <div className="m-ap-col-12 m-ap-input-f">
                                            <label className="m-ap-label">Notes</label>
                                            <textarea id="journal_notes" name="journal_notes"
                                                value={selectedJournal.Notes} readOnly>
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>

                <div className={`m-ap-accordion m-ap-m-b-15 ${transSectionActive ? 'm-ap-active' : ''}`}>
                    <div className="m-ap-ac-head" onClick={() => setTransSectionActive(!transSectionActive)}>
                        <div className="m-ap-sub-title">Transaction Details</div>
                        <div className="m-ap-arrow"></div>
                    </div>
                    {
                        transSectionActive && (
                            <div className="m-ap-ac-content">
                                <div className="m-ap-row-table">
                                    <div className="m-ap-table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="m-ap-account-id">Account ID</th>
                                                    <th className="m-ap-sub-account">Sub Account</th>
                                                    <th className="m-ap-transaction-type">Transaction Type</th>
                                                    <th className="m-ap-sub-type">Sub Type</th>
                                                    <th className="m-ap-notes">Notes</th>
                                                    <th className="m-ap-data">Data</th>
                                                    <th className="m-ap-debit">Debit</th>
                                                    <th className="m-ap-credit">Credit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    mjFormTransList.map((transaction, index) => (
                                                        <tr key={`trans-list-${index}`}>
                                                            <td scope="col">
                                                                <div className="col-md-12 m-ap-input-f">
                                                                    {transaction.AccountID}
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 m-ap-input-f">
                                                                    {transaction.SubAccount}
                                                                </div>
                                                            </td>
                                                            <td scope="col" id="journal_transtype" >
                                                                <div className="col-md-12 m-ap-input-f">
                                                                    {transaction.TranType}
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 m-ap-input-f">
                                                                    {transaction.SubType}
                                                                </div>
                                                            </td>
                                                            <td id="journal_notes">+ Add Notes</td>
                                                            <td id="journal_data">+ Add Data</td>
                                                            <td>
                                                                <div className="col-md-12 m-ap-input-f">
                                                                    {transaction.Amount}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="col-md-12 m-ap-input-f">
                                                                    {transaction.Amount}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            </div>
            <div className="m-ap-bottom">
                <button id="save-draft" onClick={goToEditJournal}>Edit Journal</button>
                <button id="cancel" onClick={goToAllJournals}>Back</button>
            </div>
        </div >
    )

}

export default JournalReceipt;