import { accountProps, searchAccountProps, ContactDetsProps, BankDetsProps, OtherDetsProps } from './interfaces';
import { BreadcrumbProps } from '../Components/interfaces';

export const AllAccountsBreadcrumbItems: BreadcrumbProps[] = [
    { title: "Policy 360", redirectTo: ""},
    { title: "Finance", redirectTo: ""},
    { title: "Accounts", redirectTo: ""},
    { title: "All Accounts", redirectTo: ""}
];

export const formData: searchAccountProps = {
    search_value: "",
    search_by: "",
};

export const sampleAccount: accountProps = {
    AccountID: "",
    LedgerID: "",
    Description: "",
    ContactDets: "",
    ActiveFrom: "",
    ActiveTo: "",
    Data: "",
    Status: "",
    Currency: "",
    DocAction: ""
}

export const sampleContactDets: ContactDetsProps = {
    ContactPerson: "",
    ContactNumber: "",
    ContactPersonEmail: "",
    Address: "",
    Pincode: "",
    City: "",
    Country: ""
}

export const sampleBankDets: BankDetsProps = {
    AcccountNo: "",
    IBAN: "",
    SwiftCode: "",
    BankName: "",
    BranchName: "",
    BankAddress: "",
    BankPincode: "",
    BankCity: "",
    BankCountry: "",
    BankDescription: ""
}

export const sampleOtherDets: OtherDetsProps = {
    OtherData: ""
}

export interface BankDetsProps {
    AcccountNo: string;
    IBAN: string;
    SwiftCode: string;
    BankName: string;
    BranchName: string;
    BankAddress: string;
    BankPincode: string;
    BankCity: string;
    BankCountry: string
    BankDescription: string;
}

export interface OtherDetsProps {
    OtherData: string;
}


export const showDate = (date: string) => {
    if (date) {
        return date.substring(0, 10);
    }
    return date;
}

export const parseDate = (date: Date) => {
    const DateToParse = date || new Date();
    return `${DateToParse.getFullYear()}-${DateToParse.getMonth() + 1}-${DateToParse.getDate()}`
}

export const allAccountsTableColumns = [
    {
        title: 'Account ID',
        width: 100,
        dataIndex: 'AccountID',
        key: 'AccountID',
    },
    {
        title: 'Account Name',
        dataIndex: 'Data',
        key: 'Data',
        width: 200,
    },
    {
        title: 'Ledger ID',
        dataIndex: 'LedgerID',
        key: 'LedgerID',
        width: 100,
    },
    {
        title: 'Active From',
        dataIndex: 'ActiveFrom',
        key: 'ActiveFrom',
        width: 100,
    },
    {
        title: 'Active To',
        dataIndex: 'ActiveTo',
        key: 'ActiveTo',
        width: 100,
    },
    {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        width: 100
    },
];