import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import endPoint from 'config/urls';
import ReceiptHeader from '../../Receipt/ReceiptHeader';
import Loader from '../../Components/Loader/Loader.componet';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs.componet';
import { Table } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { eventProps, ledgerProps, searchLedgerprops } from '../interfaces';
import {
    formData, allLedgerTable
} from '../LedgerController';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AllLedger.styles.scss';

const AllLedger = () => {

    const today = new Date();
    const [currentTab, setCurrentTab] = useState<string>('All');
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [showSuccessModal1, setShowSuccessModal1] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchData, setSearchData] = useState<searchLedgerprops>(formData);
    const [filteredLmData, setFilteredLmData] = useState<ledgerProps[]>([]);

    const gotoCreateScreen = () => {
        navigate('/CreateLedger');
    }

  
    const columns = [
        {
          title: 'Ledger #',
          dataIndex: 'LegderNo',
          key: 'LegderNo',
        },
        {
          title: 'Ledger Name',
          dataIndex: 'LedgerName',
          key: 'LedgerName',
        },
        {
          title: 'Active From',
          dataIndex: 'ActiveFrom',
          key: 'ActiveFrom',
        },
        {
            title: 'Active To',
            dataIndex: 'ActiveTo',
            key: 'ActiveTo',
          },
          {
            title: 'Ledger Type',
            dataIndex: 'LedgerType',
            key: 'LedgerType',
          },
          {
            title: 'TagRef1',
            dataIndex: 'TagRef1',
            key: 'TagRef1',
          },
          {
            title: 'TagRef2',
            dataIndex: 'TagRef2',
            key: 'TagRef2',
          },
          {
            title: 'TagRef2',
            dataIndex: 'TagRef2',
            key: 'TagRef2',
          },
          {
            title: 'Status',
            dataIndex: 'Status',
            key: 'TagRef2',
          },
          {
            title: '',
            dataIndex: 'Modify',
            key: 'TagRef2',
          },
          
    ]


   
    const data = [
        {
            "LedgerID": "L000001",
            "Description": "Some Type Of Ledger",
            "Type": "",
            "ActiveFrom": "2021-01-19T00:00:00Z",
            "ActiveTo": "2021-04-19T00:00:00Z",
            "FinancialYear": 2021,
            "FinancialPeriod": 1,
            "Tag1": "Tag1",
            "Tag2": "Tag9",
            "Data": "Data",
            "Status": "close",
            "DocAction": ""
        },
        {
            "LedgerID": "L000002",
            "Description": "Some Type Of Ledger 22",
            "Type": "",
            "ActiveFrom": "2021-01-19T00:00:00Z",
            "ActiveTo": "2021-04-19T00:00:00Z",
            "FinancialYear": 2021,
            "FinancialPeriod": 1,
            "Tag1": "Tag1",
            "Tag2": "Tag9",
            "Data": "Data",
            "Status": "close",
            "DocAction": ""
        }
      ];

    useEffect(() => {
        // //@ts-ignore
        // window.mjDetails = undefined;
        // //@ts-ignore
        // window.redirectFrom = 'AllJournal';
        // const startDate = new Date();
        // startDate.setMonth(startDate.getMonth() - 2);
        // let searchStr = "?search_by=PostingDate&value=";
        // searchStr += `${parseDate(startDate)}_${parseDate(today)}&order_by=JournalID_DESC&limit=50`;
        getLedger("");
    }, [])

      const filterledgerList = (tab: string, data: ledgerProps[] = []) => {
        // const tabName = tab === 'Posted' ? 'All' : tab;
        // setCurrentTab(tabName);
        const dataToFilter = (data.length > 0) ? data : ledgerData;
        // const filterdJournal = dataToFilter.filter(lm => {
        //     switch (tab) {
        //         case 'Drafts':
        //             return lm.Status === 'Draft' || lm.Status === "";
        //         case 'Awaiting_Approval':
        //             return lm.Status === 'Pending';
        //         case 'Posted':
        //             return lm.Status === 'Posted';
        //         default:
        //             return true;
        //     }
        // }).map(lm => {
        //     lm.ActiveFrom = showDate(lm.ActiveFrom);
        //     lm.ActiveTo = showDate(lm.ActiveTo);
        //     return lm;
        // });
        setFilteredLmData(dataToFilter);
    }

    const handleUpdate = (e: eventProps) => {
        const { name, value } = e.target;
        const newFromData = { ...searchData };
        // @ts-ignore
        newFromData[name] = value;
        setSearchData(newFromData);
        if (name === 'allJournal_filter') {
            filterledgerList(value);
        }
    }

      const searchLedger = () => {
          console.log('This is search Ledger function');
        // ?search_by=LedgerID&value=L000001
        let searchStr = "?search_by=";
        console.log("search_value", searchData.search_value,searchData.search_by);
        
        if (searchData.search_value) {
            searchStr += `${searchData.search_by}&value=${searchData.search_value}`;
        } else {
            if (searchData.from_date && searchData.to_date) {
                searchStr += `PostingDate&value=${searchData.from_date}_${searchData.to_date}&order_by=JournalID_DESC&limit=40`;
            } else {
                alert('Please enter the search criteria properly.');
            }
        }
        getLedger(searchStr);
    }

    const getLedger = async (searchStr: string) => {
            setIsLoading(true);
            https://general.sonic.dev.coherent.com.hk/general/v2/finance/v1/ledgers
            try {
                await fetch(`${endPoint.getLedger}${searchStr}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
                    .then((response: any) => {
                        const statusCode = response.status;
                        const data = response.json();
                        return Promise.all([statusCode, data]);
                    })
                    .then(([statusCode, data]) => {
                        setIsLoading(false);
                        console.log("RAsika", data )
                        if (statusCode === 200 && data.length > 0) {
                            // getLedger(data);
                            filterledgerList('All', data);
                        }
                    });
            } catch (exception) {
                setIsLoading(false);
                console.log(exception);
            }
        
    }


    return (
        <div id="all-ledger-container">
            {
                isLoading && (
                    <Loader />
                )
            }
            <ReceiptHeader />
            <div className="m-ap-wrap">
            <div
                    className="m-ap-top m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15">
                    <div className="breadcrumbs">
                        <ul className="m-ap-flex">
                            <li className="b-link"><a href="">Policy 360</a></li>
                            <li className="b-slace">/</li>
                            <li className="b-link"><a href="">Finance</a></li>
                            <li className="b-slace">/</li>
                            <li className="b-link"><a href="">Accounts</a></li>
                            <li className="b-slace">/</li>
                            <li className="b-nolink">CreateNewAccount</li>
                        </ul>
                    </div>
                    <div className="m-ap-top m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15">
                    <div className="m-ap-btn plush" onClick={gotoCreateScreen}>Create New</div>
                </div>
                </div>

                <div className="m-ap-search m-ap-m-b-15 pad-l-r-30">
                    <div className="m-ap-row search-elements">
                        <div className="m-ap-input-f m-ap-dropdown">
                            <label >Search Legder </label>
                            <select name="search_by" id="search_by" onChange={(e) => handleUpdate(e)}>
                                <option value="">Select</option>
                                <option value="LedgerID">Ledger ID</option>
                            </select>
                        </div>
                        <div className="m-ap-input-f m-ap-search-f">
                            <label>&nbsp;</label>
                            <input type="text" placeholder="Begin your search"
                                id="search_value" name="search_value" onChange={(e) => handleUpdate(e)} />
                        </div>
                        <div className="m-ap-input-f m-ap-date">
                            <label>Active Date</label>
                            <input type="date" placeholder="Begin your search"
                                id="from_date" name="from_date"/>

                        </div>
                        <div className="m-ap-input-f m-ap-to">
                            <label>To</label>
                        </div>
                        <div className="m-ap-input-f m-ap-date">
                            <label>&nbsp;</label>
                            <input type="date" placeholder="Begin your search"
                                id="to_date" name="to_date"/>
                        </div>
                        <div>
                            <button id="receipt-search" onClick={searchLedger}>Search</button>
                            <button id="clear-filter">Clear Filter</button>
                        </div>
                        {/* <div className="m-ap-input-f m-ap-flex m-ap-action">
                            <div className="m-ap-btn m-ap-search m-ap-blue" id="receipt-search" onClick={searchManualJournal}>Search</div>
                            <div className="m-ap-btn" id="clear-filter" onClick={clearFilter}>Clear Filters</div>
                        </div> */}
                    </div>
                </div>
                <div className="m-ap-filter m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15 pad-l-r-30">
                    <div className="m-ap-title">All Ledger ({filteredLmData.length}) </div>
                    <div className="m-ap-filter-dropdown m-ap-flex m-ap-align-items-center">
                        <div className="m-ap-filter-text filter-status">Filter by :Ledger Type</div>
                        <div className="m-ap-input-f">
                            <select name="allJournal_filter" id="allJournal_filter">
                                <option value="All">All</option>
                                <option value="Posted">Posted</option>
                            </select>
                        </div>
                        <div className="m-ap-filter-text filter-status"> Status</div>
                        <div className="m-ap-input-f">
                            <select name="allJournal_filter" id="allJournal_filter">
                                <option value="All">All</option>
                                <option value="Posted">Posted</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="pad-l-r-30">
                    <div className="m-ap-table-box">
                        <div className="m-ap-table">

                            {/* <Table columns={columns} dataSource={data}/> */}
                            <Table
                                columns={allLedgerTable}
                                dataSource={filteredLmData}
                                // onRow={(record, rowIndex) => {
                                //     return {
                                //         onClick: () => goToMJDetails(record)
                                //     };
                                // }}

                            />



                            {/* <table>
                                <thead>
                                    <tr>
                                        <th className="m-ap-account-id"><input type="checkbox"></input></th>
                                        <th className="m-ap-account-id">Journal #</th>
                                        <th className="m-ap-account-type">Trans. Date</th>
                                        <th className="m-ap-account-name">Post Date</th>
                                        <th className="m-ap-active-to">Debit</th>
                                        <th className="m-ap-status">Credit</th>
                                        <th className="m-ap-action">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        filteredlmData.map((lm, index) => (
                                            <tr key={`journal-list-${index}`}>
                                                <td className="m-ap-account-id"><input type="checkbox"></input></td>
                                                <td><span className="rec-id" onClick={() => goTolmDetails(lm)}>{lm.JournalID}</span></td>
                                                <td>{showDate(lm.UploadDate)}</td>
                                                <td>{showDate(lm.PostingDate)}</td>
                                                <td>{lm.NumberOfPostings}</td>
                                                <td>{lm.NumberOfPostings}</td>
                                                <td>{lm.Status}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table> */}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AllLedger;