import { BreadcrumbProps } from '../Components/interfaces';
import { accProps } from './interfaces';

export const CreateNewPeriods: BreadcrumbProps[] = [
    { title: "Policy 360", redirectTo: "" },
    { title: "Finance", redirectTo: "" },
    { title: "Accounting Period", redirectTo: "/AllPeriod" },
    { title: "Create New Account", redirectTo: "/CreateNePeriod" }
];


export const parseYearDate = (date: Date) => {
    const DateToParse = date || new Date();
    console.log("year",DateToParse.getFullYear())
    return `${DateToParse.getFullYear()}`
}

export const sampleProps: accProps = {
    FinYear:"",
    Period: "",
    Data:"",
    FromDate:"",
    ToDate:"",
    Status:""
}