import React, { useEffect, useState } from 'react';
import endPoint from 'config/urls';
import { Table } from 'antd';
import { Pagination } from 'antd';
import ReceiptHeader from '../../Receipt/ReceiptHeader';
import Loader from '../../Components/Loader/Loader.componet';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs.componet';
import './NoPeriod.styles.scss';
import { navigate } from 'raviger';


const NoPeriod = () => {



    const [isLoading, setIsLoading] = useState<boolean>(false);

    const gotoAllAccount = () => {
        navigate('/CreateNewPeriod')
    }

    return (
        <div id="no-period-container">
            {
                isLoading && (
                    <Loader />
                )
            }
            <ReceiptHeader />
            <div className="m-ap-wrap">
                <div
                    className="m-ap-top m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15">
                    <div className="breadcrumbs">
                        <ul className="m-ap-flex">
                            <li className="b-link"><a href="">Policy 360</a></li>
                            <li className="b-slace">/</li>
                            <li className="b-link"><a href="">Finance</a></li>
                            <li className="b-slace">/</li>
                            <li className="b-link"><a href="">Accounts</a></li>
                            <li className="b-slace">/</li>
                            <li className="b-nolink">CreateNewAccount</li>
                        </ul>
                    </div>
                    {/* <div className="m-ap-top m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15">
                        <div className="m-ap-btn plush">Create New</div>
                    </div> */}

                </div>
                <div className="m-ap-addbox">
                    <div className="m-ap-insidebox">
                        <div className="centered">
                            <p id="line1">Accounting period not added yet!</p>
                            <p id="line2">To add new account period click below button.</p>
                            <div className="m-ap-btn plush" onClick={gotoAllAccount}>Create New</div>
                        </div>
                    </div>
                </div>



                {/* <div className="popup-box popup-msg">
                        <div className="row success-msg success-msg-desktop">
                            <div className="background-success display-contents">
                                <span className="glyphicon glyphicon-ok-circle success-icon"><i className="bi bi-check-circle"></i></span>
                                <span className="message"> Please select only one payee code at a time </span>
                                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                                <div className="dismiss-box">
                                    <span className="payment-timestamp">12:06 15/04/2021</span>
                                    &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                                    <span className="dismiss">Dismiss </span>
                                </div>
                            </div>
                        </div>
                    </div> */}

            </div>
        </div>
    )
}

export default NoPeriod;